import dayjs from "dayjs"
var storage = {
  setItem(key, value, expired) {
    let source = {
      key: key,
      value: value
    };
    const now = Date.now();
    if (expired) {
      source.value = JSON.stringify({
        data: value,
        expired: dayjs(now).add(expired, "day")
      });
    }
    localStorage.setItem(source.key, source.value);
  },
  getItem(key) {
    const now = Date.now();
    let source = {
      key: key,
      value: null
    };
    /* 获取 localStorage 存储信息 赋值给 source 对象 */
    source.value = JSON.parse(localStorage.getItem(source.key));
    /* 如果key 有效  判断当前时间 是否超过 失效时间 */
    if (source.value) {
      if (dayjs(now).isBefore(dayjs(source.value.expired))) {
        return source.value.data;
      } else {
        /* 超过失效时间 删除 储存内容  */
        this.removeItem(source.key);
        return;

      }
    }
  },
  removeItem(key) {
    localStorage.removeItem(key);
  }
}
/*export default  用于导出 函数   类中只能定义一个 其他类通过此对象可调用 该类所有方法*/
export default storage;
