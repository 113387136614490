<template>
  <div id="app" @click="cookieTimeout">
    <router-view :key="$route.path" />
  </div>
</template>

<script>
import storage from "./js/storage";
import dayjs from "dayjs";
function throttle(fn, dalay) {
  // 节流
  var valid = true;
  return () => {
    if (!valid) {
      return false;
    }
    valid = false;
    setTimeout(() => {
      fn();
      valid = true;
    }, dalay);
  };
}
function getHoursDiff(day1, day2) {
  //计算时间差
  var day_day1 = new Date(day1);
  var day_day2 = new Date(day2);
  let disparity = day_day2.getTime() - day_day1.getTime();
  let min = Math.round(disparity / 1000 / 60);
  return min;
}
export default {
  name: "App",
  data() {
    return {
      count: 0,
      x: null,
      y: null,
    };
  },
  mounted() {
    // 监听鼠标
    document.onmousemove = (event) => {
      let x1 = event.clientX;
      let y1 = event.clientY;
      if (this.x !== x1 || this.y !== y1) {
        throttle(this.cookieTimeout(), 5000);
      }
      this.x = x1;
      this.y = y1;
    };
    // 监听键盘
    document.onkeydown = () => {
      throttle(this.cookieTimeout(), 5000);
    };
    // 监听Scroll
    document.onscroll = () => {
      throttle(this.cookieTimeout(), 1000);
    };
    document.querySelector("#app").scrollTo(0, 0);
  },
  methods: {
    cookieTimeout() {
      // 判断用户多久无操作就自动登出
      let time = getHoursDiff(
        dayjs(localStorage.getItem("count")).format("YYYY/MM/DD HH:mm"),
        dayjs().format("YYYY/MM/DD HH:mm")
      );
      if (time >= 60) {
        if (this.$route.path != "/login") {
          this.logout();
        } else {
          localStorage.setItem("count", dayjs());
        }
      } else {
        localStorage.setItem("count", dayjs());
      }
    },
    logout() {
      storage.removeItem("isType");
      storage.removeItem("token");
      this.$store.commit("setGoBack", true);
      this.$router.push({ path: "/login" });
      sessionStorage.clear();
    },
  },
};
</script>

<style>
* {
  word-wrap: break-word;
  word-break: break-word;
}
body {
  margin: 0;
  padding: 0;
  background: #f4f9fc 0% 0% no-repeat padding-box;
}
#app {
  font-family: "Roboto-Regular", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: relative;
  /* white-space: pre-wrap; */
}
#app /deep/ .el-input__inner {
  line-height: normal;
}
#app /deep/ .el-textarea__inner {
  font-family: "Roboto-Regular", sans-serif;
}
input {
  outline: none;
  line-height: normal;
}
</style>
