import Vue from "vue"
import dayjs from "dayjs";

Vue.filter("dateFormat", function (datetime, format = 'MM/DD/YYYY') {
  if (datetime === null) {
    return "";
  }
  return dayjs(datetime).format(format)
});
Vue.filter("dateFormatAdd", function (datetime, day, format = 'MM/DD/YYYY') {
  if (datetime === null) {
    return "";
  }
  return dayjs(datetime).add(day, 'day').format(format)
});
Vue.filter("distanceNow", function (datetime) { // 距离现在的天数
  if (datetime === null) {
    return "";
  }
  return dayjs(datetime).diff(dayjs(), 'day')
});

Vue.filter("arrJoin", function (arr, delimiter) {
  if (arr === undefined || arr.length === 0) {
    return "";
  }
  return arr.join(delimiter)
});
Vue.filter("character", function (chineseChar) {
  var str
  if (chineseChar) {
    str = chineseChar.replace(/\’|\‘/g, "'").replace(/\“|\”/g, "\"");
    // 将中括号【】转换成[]，将大括号｛｝转换成{}
    str = str.replace(/\【/g, "[").replace(/\】/g, "]").replace(/\｛/g, "{").replace(/\｝/g, "}");
    // 将逗号，转换成,，将：转换成:
    str = str.replace(/，/g, ",").replace(/：/g, ":");
  }
  return str;
})
Vue.filter("currencyPrice", function (value, currency, decimals) {

  const digitsRE = /(\d{3})(?=\d)/g
  value = parseFloat(value)
  if (!isFinite(value) || (!value && value !== 0)) return ''
  currency = currency != null ? currency : ''
  decimals = decimals != null ? decimals : 2
  var stringified = Math.abs(value).toFixed(decimals)
  var _int = decimals ?
    stringified.slice(0, -1 - decimals) :
    stringified
  var i = _int.length % 3
  var head = i > 0 ?
    (_int.slice(0, i) + (_int.length > 3 ? ',' : '')) :
    ''
  var _float = decimals ?
    stringified.slice(-1 - decimals) :
    ''
  // var sign = value < 0 ? '-' : ''
  var price = currency + head +
    _int.slice(i).replace(digitsRE, '$1,') +
    _float
  return price
})
Vue.filter("sliderCurrencyPrice", function (value, currency, decimals) {

  if(value == 30000) {
    return "No Max";
  }
  const digitsRE = /(\d{3})(?=\d)/g
  value = parseFloat(value)
  if (!isFinite(value) || (!value && value !== 0)) return ''
  currency = currency != null ? currency : ''
  decimals = decimals != null ? decimals : 2
  var stringified = Math.abs(value).toFixed(decimals)
  var _int = decimals ?
    stringified.slice(0, -1 - decimals) :
    stringified
  var i = _int.length % 3
  var head = i > 0 ?
    (_int.slice(0, i) + (_int.length > 3 ? ',' : '')) :
    ''
  var _float = decimals ?
    stringified.slice(-1 - decimals) :
    ''
  // var sign = value < 0 ? '-' : ''
  var price = currency + head +
    _int.slice(i).replace(digitsRE, '$1,') +
    _float
  return price
})