import Vue from 'vue'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale'
import lang from 'element-ui/lib/locale/lang/en'
import 'element-ui/lib/theme-chalk/index.css'

// 设置语言
locale.use(lang);

// 后面需要改为按需导入
Vue.use(ElementUI);
