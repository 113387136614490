import Vue from "vue";
import Vuex from 'vuex';
import {
  apiGetAreas,
  apiGetCompanyEnum,
  apiGetACTypeEnum,
  apiGetCoffeeMakerTypeEnum,
  apiGetGrillTypeEnum,
  apiGetHeatingTypeEnum,
  apiGetPollTypeEnum,
  apiGetStoveTypeEnum,
  apiGetToastTypeEnum,
  apiGetTVServiceEnum,
  apiGetFloorLevelEnum,
  apiGetBedTypeEnum,
  apiGetBathroomTypeEnum,
  apiGetPropertyPriorityEnum,
  apiGetPropertyTypeEnum,
  apiGetFinalPaymentDueEnum,
  apiGetSecurityDepositPercentageEnum,
  apiGetSecurityDepositTurnDateEnum,
  apiGetDiningCuisine,
  apiGetDiningSetting,
  apiGetTaskType,
  apiGetTennisCourtEnum,
  apiGetleaseStatus,
  apiGetRentalOpportunityLostReasons
} from '../API/api'
import storage from "../js/storage"
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    goBack: false, // 是否回退到上一个页面，用户因为没有token或token失效而自动跳转到登录界面，当登录后需要回退到之前的页面
    areas: [], // 存放地区
    enum: { // 存放枚举
      companyList: [],
      acTypeList: [],
      coffeeMakerTypeList: [],
      grillTypeList: [],
      heatingTypeList: [],
      poolTypeList: [],
      stoveTypeList: [],
      toastTypeList: [],
      tvServiceList: [],
      floorLevelList: [],
      bedTypeList: [],
      bathroomTypeList: [],
      propertyPriorityList: [],
      propertyTypeList: [],
      finalPaymentDueList: [],
      securityDepositPercentageList: [],
      securityDepositTurnDateList: [],
      restaurantCuisine: [],
      restaurantSetting: [],
      taskType: [],
      tennisCourtType: [],
      leaseStatus: [],
    },
    userInfo: {}, // 存放登录的用户信息
    listing: {}, // 存放房屋详情
    lease: {}, // 存放租约详情
    leaseParam: {}, // 跳转到创建租约页面携带的参数，listing/tenant 等
    leaseDetail: false, //判断是否已建立租约
    leaseAgeent: false, // 判断租约是否签名
    leaseSecurityDeposit: false, // 是否退还保证金
    propertyInfor: false,
    propertyCalendar: false,
    propertyPhoto: false,
    propertyReuirement: false,
    propertySevice: false,
    listingTableHeader: null, //存储房屋列表表头排序
    propertiesFilter: null, //存储房屋过滤条件
    propertiesCheckeds: [], //存储勾选的房屋列表
    contactsFilter: null, //存储contacts过滤条件
    blogFilter: null, //存储blog的过滤条件
    leasesFilter: null, //存储leases的过滤条件
    linkId: "", //存放link数据的id
    linksAllId: [], //存放选择的link的id
    competitive_listings: [], //存放过滤link的所有id
    competitive_filter_condition: {}, //存放卖出link的过滤条件
    competitive_filter_info: [], //存放过滤的房子
    cometitive_number: null, //过滤的总数
    sale_listings: [], // 存放过滤卖出的link的id
    sale_filter_condition: {}, //存放卖出link的过滤条件
    sale_filter_info: [], //存放过滤的房子
    sale_number: null, //sale 过滤的总数
    cmaPDFUrl: "", //cma pdf的链接
    propertyCard: null,
    lostReasons: []
  },
  mutations: {
    setCmaPDFUrl(state, cmaPDFUrl) {
      state.cmaPDFUrl = cmaPDFUrl
    },
    setSaleFilterCondition(state, sale_filter_condition) {
      state.sale_filter_condition = sale_filter_condition
    },
    setSaleFilterInfo(state, sale_filter_info) {
      state.sale_filter_info = sale_filter_info
    },
    setSaleNumber(state, sale_number) {
      state.sale_number = sale_number
    },
    setSaleListings(state, sale_listings) {
      state.sale_listings = sale_listings
    },
    setCompetitiveFilterCondition(state, competitive_filter_condition) {
      state.competitive_filter_condition = competitive_filter_condition
    },
    setCompetitiveFilterInfo(state, competitive_filter_info) {
      state.competitive_filter_info = competitive_filter_info
    },
    setCompetitiveListings(state, competitive_listings) {
      state.competitive_listings = competitive_listings
    },
    setCompetitiveNumber(state, cometitive_number) {
      state.cometitive_number = cometitive_number
    },
    setlinksAllId(state, linksAllId) {
      state.linksAllId = linksAllId
    },
    setLinkId(state, linkId) {
      state.linkId = linkId
    },
    setLeasesFilter(state, leasesFilter) {
      state.leasesFilter = leasesFilter
    },
    setListingTableHeader(state, listingTableHeader) {
      state.listingTableHeader = listingTableHeader
      storage.setItem(listingTableHeader, JSON.stringify(listingTableHeader))
    },
    setPropertiesChecked(state, propertiesCheckeds) {
      state.propertiesCheckeds = propertiesCheckeds;
    },
    setPropertiesFilter(state, propertiesFilter) {
      state.propertiesFilter = propertiesFilter
    },
    setContactsFilter(state, contactsFilter) {
      state.contactsFilter = contactsFilter
    },
    setBlogFilter(state, blogFilter) {
      state.blogFilter = blogFilter
    },
    setGoBack(state, bool) {
      state.goBack = bool
    },
    setAreas(state, areas) {
      state.areas = areas
    },
    setCompanyList(state, companyList) {
      state.enum.companyList = companyList
    },
    setACTypeList(state, acTypeList) {
      state.enum.acTypeList = acTypeList
    },
    setCoffeeMakerTypeList(state, coffeeMakerTypeList) {
      state.enum.coffeeMakerTypeList = coffeeMakerTypeList
    },
    setGrillTypeList(state, grillTypeList) {
      state.enum.grillTypeList = grillTypeList
    },
    setHeatingTypeList(state, heatingTypeList) {
      state.enum.heatingTypeList = heatingTypeList
    },
    setPoolTypeList(state, poolTypeList) {
      state.enum.poolTypeList = poolTypeList
    },
    setStoveTypeList(state, stoveTypeList) {
      state.enum.stoveTypeList = stoveTypeList
    },
    setToastTypeList(state, toastTypeList) {
      state.enum.toastTypeList = toastTypeList
    },
    setTVServiceList(state, tvServiceList) {
      state.enum.tvServiceList = tvServiceList
    },
    setFloorLevelList(state, floorLevelList) {
      state.enum.floorLevelList = floorLevelList
    },
    setBedTypeList(state, bedTypeList) {
      state.enum.bedTypeList = bedTypeList
    },
    setBathroomTypeList(state, bathroomTypeList) {
      state.enum.bathroomTypeList = bathroomTypeList
    },
    setPropertyPriorityList(state, propertyPriorityList) {
      state.enum.propertyPriorityList = propertyPriorityList
    },
    setPropertyTypeList(state, propertyTypeList) {
      state.enum.propertyTypeList = propertyTypeList
    },
    setFinalPaymentDueList(state, finalPaymentDueList) {
      state.enum.finalPaymentDueList = finalPaymentDueList
    },
    setSecurityDepositPercentageList(state, securityDepositPercentageList) {
      state.enum.securityDepositPercentageList = securityDepositPercentageList
    },
    setSecurityDepositTurnDateList(state, securityDepositTurnDateList) {
      state.enum.securityDepositTurnDateList = securityDepositTurnDateList
    },

    setrestaurantCuisineList(state, restaurantCuisine) {
      state.enum.restaurantCuisine = restaurantCuisine
    },
    setrestaurantSettingList(state, restaurantSetting) {
      state.enum.restaurantSetting = restaurantSetting
    },
    settaskType(state, taskType) {
      state.enum.taskType = taskType
    },
    // tennisCourtType
    settennisCourtType(state, tennisCourtType) {
      state.enum.tennisCourtType = tennisCourtType
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
    },
    setListing(state, listing) {
      state.listing = listing
    },
    setPropertyCard(state, listing) {
      state.propertyCard = listing  
    },
    setLease(state, lease) {
      state.lease = lease
    },
    setLeaseParam(state, leaseParam) {
      state.leaseParam = leaseParam
    },
    // leaseAgeent:false,
    // leaseSecurityDeposit:false,
    setLeaseDetail(state, leaseDetail) {
      state.leaseDetail = leaseDetail
    },
    setLeaseAgeent(state, leaseAgeent) {
      state.leaseAgeent = leaseAgeent
    },
    setLeaseSecurityDeposit(state, leaseSecurityDeposit) {
      state.leaseSecurityDeposit = leaseSecurityDeposit
    },
    // 房屋
    setPropertyInfor(state, propertyInfor) {
      state.propertyInfor = propertyInfor
    },

    setPropertyCalendar(state, propertyCalendar) {
      state.propertyCalendar = propertyCalendar
    },
    setPropertyPhoto(state, propertyPhoto) {
      state.propertyPhoto = propertyPhoto
    },
    setPropertyReuirement(state, propertyReuirement) {
      state.propertyReuirement = propertyReuirement
    },
    setPropertySevice(state, propertySevice) {
      state.propertySevice = propertySevice
    },
    setLeaseStatus(state, leaseStatus) {
      state.enum.leaseStatus = leaseStatus
    },
    setLostReasons(state, reasons) {
      state.lostReasons = reasons
    }
  },
  actions: {
    getAreas(context) {
      apiGetAreas().then(res => {
        context.commit('setAreas', res.results)
      })
    },
    getCompanyList(context) {
      apiGetCompanyEnum().then(res => {
        context.commit('setCompanyList', res)
      })
    },
    getACTypeList(context) {
      apiGetACTypeEnum().then(res => {
        context.commit('setACTypeList', res)
      })
    },
    getCoffeeMakerTypeList(context) {
      apiGetCoffeeMakerTypeEnum().then(res => {
        context.commit('setCoffeeMakerTypeList', res)
      })
    },
    getGrillTypeList(context) {
      apiGetGrillTypeEnum().then(res => {
        context.commit('setGrillTypeList', res)
      })
    },
    getHeatingTypeList(context) {
      apiGetHeatingTypeEnum().then(res => {
        context.commit('setHeatingTypeList', res)
      })
    },
    getPoolTypeList(context) {
      apiGetPollTypeEnum().then(res => {
        context.commit('setPoolTypeList', res)
      })
    },
    getStoveTypeList(context) {
      apiGetStoveTypeEnum().then(res => {
        context.commit('setStoveTypeList', res)
      })
    },
    getToastTypeList(context) {
      apiGetToastTypeEnum().then(res => {
        context.commit('setToastTypeList', res)
      })
    },
    getTVServiceList(context) {
      apiGetTVServiceEnum().then(res => {
        context.commit('setTVServiceList', res)
      })
    },
    getFloorLevelList(context) {
      apiGetFloorLevelEnum().then(res => {
        context.commit('setFloorLevelList', res)
      })
    },
    getBedTypeList(context) {
      apiGetBedTypeEnum().then(res => {
        context.commit('setBedTypeList', res)
      })
    },
    getBathroomTypeList(context) {
      apiGetBathroomTypeEnum().then(res => {
        context.commit('setBathroomTypeList', res)
      })
    },
    getPropertyPriorityList(context) {
      apiGetPropertyPriorityEnum().then(res => {
        context.commit('setPropertyPriorityList', res)
      })
    },
    getPropertyTypeList(context) {
      apiGetPropertyTypeEnum().then(res => {
        context.commit('setPropertyTypeList', res)
      })
    },
    getFinalPaymentDueList(context) {
      apiGetFinalPaymentDueEnum().then(res => {
        context.commit('setFinalPaymentDueList', res)
      })
    },
    getSecurityDepositPercentageList(context) {
      apiGetSecurityDepositPercentageEnum().then(res => {
        context.commit('setSecurityDepositPercentageList', res)
      })
    },
    getSecurityDepositTurnDateList(context) {
      apiGetSecurityDepositTurnDateEnum().then(res => {
        context.commit('setSecurityDepositTurnDateList', res)
      })
    },
    getrestaurantCuisineList(context) {
      apiGetDiningCuisine().then(res => {
        context.commit('setrestaurantCuisineList', res)
      })
    },
    getTennistCourtList(context) {
      apiGetTennisCourtEnum().then(res => {
        context.commit('settennisCourtType', res)
      })
    },
    getrestaurantSettingList(context) {
      apiGetDiningSetting().then(res => {
        context.commit('setrestaurantSettingList', res)
      })
    },
    getTaskType(context) {
      apiGetTaskType().then(res => {
        context.commit('settaskType', res)
      })
    },
    getLeaseStatus(context) {
      apiGetleaseStatus().then(res => {
        context.commit('setLeaseStatus', res)
      })
    },
    getLostReasons(context) {
      if(!context.state.lostReasons.length) {
        apiGetRentalOpportunityLostReasons().then(res=>{
          context.commit('setLostReasons', res.results)
        })
      }
    }
  },
  getters: {
    readMsg(state) {
      return state.userInfo
    }
  }
})
