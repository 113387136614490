// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import 'lib-flexible'
import router from './router'
import store from './js/store'
import './js/element'
import './js/lazyload'
import './js/filters'
import "./css/common.css"
import "./css/main.css"
import "font-awesome/css/font-awesome.min.css";
import Mint from 'mint-ui';
import 'mint-ui/lib/style.css';
import  '@/plugins'


import './mixins'

Vue.use(Mint);
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});
Vue.config.productionTip = false
new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
