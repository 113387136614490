import { get, post, patch, del } from './https'

// 登录
export const apiLogin = params => { return post('/user-auth', params) };
// 注册
export const apiRegiser = params => { return post('/register-user', params) };
// 发送邮件
export const apiEmail = params => { return post('/send-forget-password-email', params) };
// 第三方登录
export const apiThirdparty = params => { return post("/third-party-auth", params) };
// 忘记密码
export const apiForgetpassword = params => { return post("/forget-password", params) };
// 修改密码
export const apiResetpassword = params => { return post("/reset-password", params) }

// 上传图片
export const apiUploadImage = (data) => { return post("/upload/image", data) }
// 上传文件
export const apiUploadFile = (data) => { return post("/upload/file", data) }


// User API
// 获取用户列表
export const apiGetUsers = (params) => { return get(`/users`, params) };
// 创建用户
export const apiCreateUser = (data) => { return post(`/users`, data) };
// 获取用户
export const apiGetUser = (id, params) => { return get(`/users/${id}`, params) };
// 修改用户
export const apiUpdateUser = (id, data) => { return patch(`/users/${id}`, data) };

// 获取个人信息
export const apiGetProfile = params => { return get("/profile", params) }
// 修改个人信息
export const apiUpdateProfile = params => { return patch("/profile", params) }

// 管理员授权
export const apiGrantPermission = params => { return post("/grant-permission", params) }
// 管理员取消授权
export const apiRevokePermission = params => { return post("/revoke-permission", params) }

// 通知管理员修改ho信息
export const apiUpdateHomeownerInfor = params => { return post("/request-modify-ho-info", params) }

// 排版 API
// 获取轮班顺序
export const apiGetShiftOrder = (params) => { return get(`/shift-order`, params) };
// 设置轮班顺序
export const apiCreateShiftOrder = (data) => { return post(`/shift-order`, data) };
// 获取轮班时间
export const apiGetShiftTime = (params) => { return get(`/shift-time`, params) };
// 设置轮班时间
export const apiCreateShiftTime = (data) => { return post(`/shift-time`, data) };
// 获取轮班
export const apiGetSchedule = (params) => { return get(`/schedule`, params) }
// 设置轮班
export const apiCreateSchedule = (data) => { return post(`/schedule`, data) }

// Contact API
// 获取联系人列表
export const apiGetContacts = (params) => { return get("/contacts", params) };
// 创建联系人
export const apiCreateContact = (data) => { return post("/contacts", data) };
// 获取联系人
export const apiGetContact = (id, params) => { return get(`/contacts/${id}`, params) };
// 修改联系人
export const apiUpdateContact = (id, data) => { return patch(`/contacts/${id}`, data) };
// 删除联系人
export const apiDeleteContact = (id, data) => { return del(`/contacts/${id}`, data) };

// Contact Comment API
// 获取联系人备注列表
export const apiGetContactComments = (id, params) => { return get(`/contacts/${id}/comments`, params) };
// 创建联系人备注
export const apiCreateContactComment = (id, data) => { return post(`/contacts/${id}/comments`, data) };
// 删除联系人备注
export const apiDelContactComment = (id, commmentId, data) => { return del(`/contacts/${id}/comments/${commmentId}`, data) };

// Tasks API
// 获取任务列表
export const apiGetTasks = (params) => { return get("/tasks", params) }
// 创建任务
export const apiCreatTask = (data) => { return post("/tasks", data) }
// 获取任务详情
export const apiGetTask = (id, params) => { return get(`/tasks/${id}`, params) }
// 修改任务
export const apiUpdateTask = (id, data) => { return patch(`/tasks/${id}`, data) }
// 修改任务
export const apiDelTask = (id, data) => { return del(`/tasks/${id}`, data) }
//  type 的枚举
export const apiGetTaskType = (params) => { return get('/enum-task-type', params) }

// Contact Communication Log API
// 获取通讯日志列表
export const apiGetCommunicationLogs = (id, params) => { return get(`/contacts/${id}/communication-log`, params) };
// 创建通讯日志
// export const apiCreateCommunicationLog = (id, data) => {
//   return post(`/contacts/${id}/communication-log`, data)
// };

// Listing API
// 获取房屋列表
export const apiGetListings = (params) => { return get("/listings", params) };
// 创建房屋
export const apiCreateListing = (data) => { return post("/listings", data) };
// 获取房屋
export const apiGetListing = (id, params) => { return get(`/listings/${id}`, params) };
// 修改房屋
export const apiUpdateListing = (id, data) => { return patch(`/listings/${id}`, data) };
// 发布房屋
export const apiPublishListing = (id, data) => { return post(`/listings/${id}/publish`, data) }
// 隐藏房屋(取消发布)
export const apiHideListing = (id, data) => { return post(`/listings/${id}/hide`, data) }
// 禁用房屋
export const apiDeactivateListing = (id, data) => { return post(`/listings/${id}/deactivate`, data) }
// 分享房屋
export const apiShareListings = (data) => { return post('/share-listings', data) };
// send email to owners
export const apiEmailOwners = (data) => { return post('/email-owners', data) };
// send email to contacts
export const apiEmailToContact = (data) => { return post('/email-contact', data) };
// export contacts
export const apiExportContacts = (data) => { return post('/export-contacts', data) };
// send email to owners
export const apiReplyToCommunicationLog = (data) => { return post('/communication-log-reply', data) };


// 创建房屋列表的评论
export const apiCreateListingComment = (id, data) => { return post(`/listings/${id}/comments`, data) }
// 获取房屋列表的评论
export const apiGetListingComments = (id, data) => { return get(`/listings/${id}/comments`, data) }
// 修改房屋列表的评论
export const apiUpdateListingComments = (id, commentId, data) => { return patch(`/listings/${id}/comments/${commentId}`, data) }
// 删除房屋列表的评论
export const apiDelListingComments = (id, commentId, data) => { return del(`/listings/${id}/comments/${commentId}`, data) }
// 下载房屋PDF文件
export const apiDownloadListingPDF = (data) => { return post("/download-listing-pdf", data, "blob") }
export const apiDownloadListingPhotoPDF = (data) => { return post("/download-listing-photos-pdf", data, "blob") }
// 获取房屋日历的更新记录
export const apiGetCalendarUpdate = (id, data) => { return get(`/listings/${id}/calendar-update-log`, data) }
// Update property calendar by importing calendar links
export const apiUpdatePropertyCalendar = (data) => { return post("/import-calendar", data) }

// 服务供应商
// 创建服务供应商
export const apiCreateServiceProvider = (params) => { return post("/service-provider", params) }
//  获取服务供应商列表
export const apiGetServiceProviders = (params) => { return get("/service-provider", params) }
//  修改服务供应商列表
export const apiUpdateServiceProvider = (id, params) => { return patch(`/service-provider/${id}`, params) }
// 创建服务供应者评论
export const apiCreateServiceProviderComment = (id, params) => { return post(`/service-provider/${id}/comments`, params) }
//  获取服务供应者评论列表
export const apiGetServiceProvidersComments = (id, params) => { return get(`/service-provider/${id}/comments`, params) }
export const apiDeleteServiceProviderComment = (id, commmentId) => { return del(`/service-provider/${id}/comments/${commmentId}`) }


// Lease API
// 获取租约列表
export const apiGetLeases = (params) => { return get("/leases", params) };
// 创建租约
export const apiCreateLease = (data) => { return post("/leases", data) };
// 获取租约
export const apiGetLease = (id, params) => { return get(`/leases/${id}`, params) };
// 修改租约
export const apiUpdateLease = (id, data) => { return patch(`/leases/${id}`, data) };
// 删除租约
export const apiDeleteLease = (id, data) => { return del(`/leases/${id}`, data) };
// 计算租金
export const apiCalculateRent = (data) => { return post("/calculate-rent", data) }
// 发送租约表单
export const apiSendDisbursementForm = (data) => { return post("/send-disbursement-form", data) }
// 发送电子签名
export const apiSendSign = (data) => { return post("/send-sign", data) }
// 获取签名文件
export const apiGetSignFile = (params) => { return get("/get-sign-file", params, "blob") }
// 上传签名文件
export const apiUploadSignedFile = (data) => { return post("/upload-signed-file", data) }
// 发送一个提醒 tenant
export const apiSendNotification = (params) => { return post("/notification-tenant-payment-due", params) }
// agent
export const apiSendNotificationAgent = (params) => { return post("/notification-agent-payment-due", params) }
// Admin修改财务报表
export const apiUpdateDisbursement = (id, params) => { return patch(`/disbursement-form/${id}`, params) }
// 更新QB信息
export const apiUpdateQBInfor = (params) => { return post("/update-qb-info", params) }
export const apiUpdateSDQBInfor = (params) => { return post("/update-sd-qb-info", params) }
// 创建财务表报的评论
export const apiCreateDisbursementForm = (id, params) => { return post(`/disbursement-form/${id}/comments`, params) }
export const apiDelDisbursementForm = (id, fileid, params) => { return del(`/disbursement-form/${id}/comments/${fileid}`, params) }
// 获取列表
export const apiGetDisbursementForm = (id, params) => { return get(`/disbursement-form/${id}/comments`, params) }
// 解锁租约的日期
export const apiCreateUnblockDate = (params) => { return post("/unblock-lease-date", params) }
// 锁定租约日期
export const apiCreateBlockDate = (params) => { return post("/block-lease-date", params) }
// 下载文档
export const apiDownloadLeaseReport = (params) => { return post("/download-lease-report-csv", params, "blob") }
// 退还保证金
export const apiReturnSecurity = (id, params) => { return post(`/security-deposit/${id}/return`, params) }
// 获取保证金备注列表
export const apiGetSecurityDepositComment = (id, params) => { return get(`/security-deposit/${id}/comments`, params) }
// 创建保证金备注
export const apiCreateSecurityDepositComment = (id, params) => { return post(`/security-deposit/${id}/comments`, params) }
// 上传保证金附件
export const apiSecurityDepositUploadFile = (id, params) => { return post(`/security-deposit/${id}/upload-file`, params) }
// 删除单个保证金的附件
export const apiRemoveFile = (id, params) => { return post(`/security-deposit/${id}/remove-file`, params) }
// 删除保证金的备注
export const apiDeleteSecurity = (id, fileid, params) => { return del(`/security-deposit/${id}/comments/${fileid}`, params) }
// 下载财务报表的pdf
export const apiDownloadDisbursement = (params) => { return post("/download-disbursement-form-pdf", params, "blob") }


// Blog API
// blog
export const apiGetBlogs = (params) => { return get("/blogs", params) }
export const apiCreateBlog = (data) => { return post("/blogs", data) }
export const apiGetBlog = (id, params) => { return get(`/blogs/${id}`, params) }
export const apiUpdateBlog = (id, data) => { return patch(`/blogs/${id}`, data) }
export const apiDelBlog = (id, data) => { return del(`/blogs/${id}`, data) }
// blog标签
export const apiCreateTags = (params) => { return post("/tags", params) }
export const apiGetTags = (params) => { return get(`/tags`, params) }
export const apiDelTags = (id, data) => { return del(`/tags/${id}`, data) }

//Get Agents
export const apiAgent = () => { return get(`/agents`) }

// 区域 API
// 获取区域列表
export const apiGetAreas = (params) => { return get('/area', params) };
// 获取区域详情
export const apiGetArea = (params) => { return get(`/area/${id}`, params) };
// 创建区域
export const apiCreateArea = params => { return post("/area", params) }
// 修改区域
export const apiUpdateArea = (id, params) => { return patch(`/area/${id}`, params) }


// 模板
// 上传模板
export const apiUploadTempalte = params => { return post("/upload/template", params) }
// 获取模板文件分类
export const apiGetTemplateCategory = (data) => { return get("/template-file-category", data) }
// 创建模板文件分类
export const apiCreateTemplateCategory = (data) => { return post("/template-file-category", data) }
// 删除模板文件分类
export const apiDelTemplateCategory = (id, data) => { return del(`/template-file-category/${id}`, data) }

// 上传模板文件
export const apiCreateTempalteFile = params => { return post("/template-file", params) }
// 获取模板文件列表
export const apiGetTemplatels = params => { return get("/template-file", params) }
// 获取模板文件详情
export const apiGetTemplatel = (id, params) => { return get(`/template-file/${id}`, params) }
// 删除模板
export const apiDelTemplate = (id, params) => { return del(`/template-file/${id}`, params) }
// 分享模板
export const apiShareTemplate = (params) => { return post("/share-template-file", params) }

// resources
// 创建餐饮
export const apiCreateRestaurant = id => { return post("/restaurant", id) }
// 获取餐饮列表
export const apiGetRestaurants = id => { return get("/restaurant", id) }
// 获取餐饮详情
export const apiGetRestaurant = (id, params) => { return get(`/restaurant/${id}`, params) }
// 修改餐饮
export const apiUploadRestaurant = (id, params) => { return patch(`/restaurant/${id}`, params) }
// 删除餐饮
export const apiDelRestaurant = (id, params) => { return del(`/restaurant/${id}`, params) }

// covid
// 创建covid
export const apiCreateQA = params => {
    return post("/qa", params)
}
// 获取列表
export const apiGetQAs = params => { return get("/qa", params) }
// 获取详情
export const apiGetQA = (id, params) => { return get(`/qa/${id}`, params) }
// 修改
export const apiUploadQA = (id, params) => { return patch(`/qa/${id}`, params) }
// 删除
export const apiDelQA = (id, params) => { return del(`/qa/${id}`, params) }


// Enum API
// 公司枚举
export const apiGetCompanyEnum = (params) => { return get('/enum-company', params) };
// 空调类型枚举
export const apiGetACTypeEnum = (params) => { return get('/enum-ac-type', params) };
// 咖啡机类型枚举
export const apiGetCoffeeMakerTypeEnum = (params) => { return get('/enum-coffee-maker-type', params) };
// 烤架类型枚举
export const apiGetGrillTypeEnum = (params) => { return get('/enum-grill-type', params) };
// 加热类型枚举
export const apiGetHeatingTypeEnum = (params) => { return get('/enum-heating-type', params) };
// 泳池类型枚举
export const apiGetPollTypeEnum = (params) => { return get('/enum-pool-type', params) };
// 火炉类型枚举
export const apiGetStoveTypeEnum = (params) => { return get('/enum-stove-type', params) };
// 烤面包机类型枚举
export const apiGetToastTypeEnum = (params) => { return get('/enum-toaster-type', params) };
// 电视服务枚举
export const apiGetTVServiceEnum = (params) => { return get('/enum-tv-service', params) };
// 网球场枚举
export const apiGetTennisCourtEnum = (params) => { return get('/enum-tennis-court-type', params) };
// 楼层枚举
export const apiGetFloorLevelEnum = (params) => { return get('/enum-floor-level', params) };
// 卧室床的类型枚举
export const apiGetBedTypeEnum = (params) => { return get("/enum-bed-type", params) }
// 浴室类型枚举
export const apiGetBathroomTypeEnum = (params) => { return get('/enum-bathroom-type', params) };
// 房屋优先级枚举
export const apiGetPropertyPriorityEnum = (params) => { return get("/enum-property-priority", params) }
// 房屋类型枚举
export const apiGetPropertyTypeEnum = (params) => { return get("/enum-property-type", params) }
// 最终付款到期枚举
export const apiGetFinalPaymentDueEnum = (params) => { return get("/enum-final-payment-due", params) }
// 保证金比例枚举
export const apiGetSecurityDepositPercentageEnum = (params) => { return get("/enum-security-deposit-percentage", params) }
// 退押金日期枚举
export const apiGetSecurityDepositTurnDateEnum = (params) => { return get("/enum-security-deposit-return-date", params) }
// 餐饮美食枚举
export const apiGetDiningCuisine = (params) => { return get("/enum-dining-cuisine", params) }
// 餐饮主题枚举
export const apiGetDiningSetting = (params) => { return get("/enum-dining-setting", params) }
// 租约状态枚举
export const apiGetleaseStatus = (params) => { return get("/enum-lease-status", params) }
// 支票
// 创建支票
export const apiCreateCheckPayment = (params) => { return post("/check-payment", params) }
// 修改支票
export const apiUpdateCheckPayment = (id, params) => { return patch(`/payment/${id}`, params) }
// 删除支票
export const apiDelCheckPayment = (id, params) => { return del(`/checks/${id}`, params) }

// 第三方
export const apiThirdData = (params) => { return post("/query-gmail-mail", params) }

// 创建bill
export const apiCreateBill = (params) => { return post("/qb-create-bill", params) }

// dashboard 数据请求
export const apiGetListingSummary = (params) => { return get('/link-listings-summary', params) }

// 根据日期获取租约的id
export const apiGetLeaseId = (params) => { return post('/get-lease', params) }


// 请求link的数据
export const apiGetLinkListings = params => { return get("/link-listings", params) }
// 请求link的数据
export const apiCreateLinkPDF = params => { return post("/download-link-listing-cma-pdf", params, "blob") }
// 创建link的评论
export const apiCreatedLinkListingComment = (id, params) => { return post(`/link-listings/${id}/comments`, params) };
// 获取link的评论
export const apiGetLinkListingComments = (id, params) => { return get(`/link-listings/${id}/comments`, params) };
// 删除link的评论 {{HTTP}}/link-listings/86042
export const apiDelLinkListingComments = (id, commentId, params) => { return del(`/link-listings/${id}/comments/${commentId}`, params) };
// 修改link的评论 {{HTTP}}/link-listings/86042
export const apiUpdateLinkListingComments = (id, params) => { return patch(`/link-listings/${id}`, params) };
// 创建link
export const apiCreateLink = (params) => { return post("/link-listings", params) }
// 发送邮件，通知租客退还押金
export const apiSendOwnerRelease = (params) => { return post("/notification-owner-release-sd", params) }

// Tools & Resources API

export const apiGetExternalTools = params => { return get(`/external-tool`, params) }


export const apiCreateExternalTool = (data) => { return post(`/external-tool`, data) };

export const apiGetExternalTool = (id, params) => { return get(`/external-tool/${id}`, params) };

export const apiUpdateExternalTool = (id, data) => { return patch(`/external-tool/${id}`, data) };

export const apiDeleteExternalTool = (id, data) => { return patch(`/external-tool/${id}`, data) };


// rental opportunity
export const apiCreateRentalOpportunity = (params) => { return post("/rental-opportunity", params) }
export const apiGetRentalOpportunity= (params) => { return get("/rental-opportunity", params) };
export const apiUpdateRentalOpportunity = (id,params) => {return patch(`/rental-opportunity/${id}`,params)}
export const apiGetRentalOpportunityById= (id,params) => { return get(`/rental-opportunity/${id}`, params) };

// lost reasons
export const apiGetRentalOpportunityLostReasons = () => {return get('/rental-opportunity-lost-reason') }

// Saved Search APIs

export const apiCreateRentalListingSearchCriteria = (id,params) => { return post(`/rental-listing-search-criteria?contact=${id}`, params)}
export const apiUpdateRentalListingSearchCriteria = (id,params) => { return patch(`/rental-listing-search-criteria/${id}`, params)}
export const apiDeleteRentalListingSearchCriteria = (id, params) => { return del(`/rental-listing-search-criteria/${id}`, params)}
export const apiGetRentalListingSearchCriteria = (id) => { return get(`/rental-listing-search-criteria?contact=${id}`)}
export const apiGetRealEstateSearchCriteria = (id) => { return get(`/real-estate-search-criteria?contact=${id}`)}
export const apiCreateRealEstateSearchCriteria = (id, params) => { return post(`/real-estate-search-criteria?contact=${id}`, params)}
export const apiUpdateRealEstateSearchCriteria = (id, params) => { return patch(`/real-estate-search-criteria/${id}`, params)}
export const apiDeleteRealEstateSearchCriteria = (id, params) => { return del(`/real-estate-search-criteria/${id}`, params)}
export const apiGetSavedSearchEnums = () => { return get(`/saved-search-enums`)}
