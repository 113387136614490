import axios from 'axios';
import qs from 'qs'
import Router from '@/router/index'
import store from '../js/store'
import storage from "../js/storage"
var root = process.env.VUE_APP_BASE_URL;
import { Message } from 'element-ui';
axios.defaults.baseURL = root;
// axios.defaults.baseURL = "api";
axios.defaults.timeout = 120000; // 超时时间设置为2分钟，下载文件时时间会比较长
// 请求拦截器
axios.interceptors.request.use(
  config => {
    // 请求地址是 https://devapi.booknantucket.com 开头的才加 token
    if (config.url.startsWith("/")) {
      const token = storage.getItem("token");
      token && (config.headers.Authorization = `JWT ${token}`);
      config.headers['Content-Type'] = 'application/json';
    }
    return config;
  },
  error => {
    return Promise.error(error);
  }
);

// 响应拦截器
axios.interceptors.response.use(
  
  response => {
    return response;
    // const err = new Error(data.description)
    // err.data = data
    // return data;
  },
  error => {
    // 发送邮件用到 google api，可能会报 401，直接返回
    // if (error.config.url.startsWith("https://www.googleapis.com")) {
    //   return Promise.reject(error);
    // }

    if (error.response.status === 401) { // Token 失效，删除 sessionStorage 存储的 token， 并跳转到登录页面
      if (error.config.baseURL.startsWith(axios.defaults.baseURL)) {
        Router.push("/login");
        storage.removeItem("token");
        store.commit('setGoBack', true); // 下次登录成功后，需要回退到上一个页面
      }
    }else{
      const status = error.response.status;
      const res = error.response.data;
      // if (/^4/.test(status)) {
      //   if (res.detail) {
      //     Message.error(res.detail);
      //   } else {
      //     // error.response.statusText
      //     Message.error("fail");
      //   }
      // }
  
      if (status == 500) {
        Message.error("something unexpected happend");
      }
    }
    return Promise.reject(error);
  }
);
// listingsTableHeader 
/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */
export function get(url, params = {}, responseType = 'json') {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: params,
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
      responseType: responseType
    }).then(response => {
      if (responseType === 'blob') {
        resolve(response);
      } else {
        resolve(response.data);
      }
    }, err => {
      reject(err)
    })
  })
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function post(url, data = {}, responseType = 'json') {
  return new Promise((resolve, reject) => {
    axios.post(url, data, { responseType: responseType }).then(response => {
      if (responseType === 'blob') {
        resolve(response);
      } else {
        resolve(response.data);
      }
    }, err => {
      reject(err)
    })
  })
}


/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function put(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.put(url, data).then(response => {
      resolve(response.data);
    }, err => {
      reject(err)
    })
  })
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function patch(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.patch(url, data).then(response => {
      resolve(response.data);
    }, err => {
      reject(err)
    })
  })
}

/**
 * 封装deleter请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function del(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.delete(url, {
      data: data
    }).then(response => {
      resolve(response.data);
    }, err => {
      reject(err)
    })
  })
}
