export default {
  computed: {
    emailError (){
      return this.handleServerError('email')
    },
    firstNameError(){
      return this.handleServerError('first_name')
    },
    lastNameError(){
      return this.handleServerError('last_name')
    },
    phoneError(){
      return this.handleServerError('phone')
    },
    propertyAddressError(){
      return this.handleServerError('property_address')
    },
  },
  methods: {
    handleServerError(key) {
      return ''
    }
  }
}